import {
  ContentItem,
  ContentItemResult,
  ContentItemSummaryResult,
  ContentItemType,
  DraftUserExternalLinkContentItemResult,
  DraftUserExternalLinkContentItemSummaryResult,
  ExternalLinkType,
  UserArticleContentItemResult,
  UserArticleContentItemSummaryResult,
  UserExternalLinkContentItemResult,
  UserExternalLinkContentItemSummaryResult,
} from "@/api/DoceoApi";
import { filter } from "vue/types/umd";

const COLORS = {
  DEFAULT: "#cccccc",
  ARTICLE: {
    border: "#ebd064",
    filled: "#fcf8e7",
  },
  DRUG: {
    border: "#89aec9",
    filled: "#e7f0f7",
  },
  DECISION: {
    border: "#c0d697",
    filled: "#f1f7e7",
  },
  TRIALS: {
    border: "#feb339",
    filled: "#fcf6ec",
  },
  USER_ARTICLE: {
    border: "#ebd064",
    filled: "#fcf8e7",
  },
  DRAFT_USER_ARTICLE: {
    border: "#cccccc",
    filled: "#f7f7f7",
  },
  USER_EXTERNAL_LINK: {
    border: "#ebd064",
    filled: "#33bfb3",
  },
  DRAFT_USER_EXTERNAL_LINK: {
    border: "#cccccc",
    filled: "#f7f7f7",
  },
  FOLIO: {
    border: "#cccccc",
    filled: "#f7f7f7",
  },
  EXTERNAL_DEFAULT: {
    border: "#33bfb3",
    filled: "#f7f7f7",
  },
  EXTERNAL_FACEBOOK: {
    border: "#1b74e4",
    filled: "#f7f7f7",
  },
  EXTERNAL_TWITTER: {
    border: "#1d9bf0",
    filled: "#f7f7f7",
  },
  EXTERNAL_INSTAGRAM: {
    border: "#e90c2b",
    filled: "#f7f7f7",
  },
  EXTERNAL_LINKEDIN: {
    border: "#0e66c2",
    filled: "#f7f7f7",
  },
  EXTERNAL_YOUTUBE: {
    border: "#fd0d1b",
    filled: "#f7f7f7",
  },
  EXTERNAL_JOURNAL: {
    border: "#f0a0a0",
    filled: "#ffefef",
  },
};

export function getContentSummaryColor(contentItemSummary: ContentItemSummaryResult): string {
  switch (contentItemSummary.contentItemType) {
    case ContentItemType.Article:
      return COLORS.ARTICLE.border;
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return COLORS.DRUG.border;
    case ContentItemType.Decision:
      return COLORS.DECISION.border;
    case ContentItemType.Trial:
      return COLORS.TRIALS.border;
    case ContentItemType.Folio:
      return COLORS.FOLIO.border;
    case ContentItemType.UserArticle:
      return COLORS.USER_ARTICLE.border;
    case ContentItemType.DraftUserArticle:
      return COLORS.DRAFT_USER_ARTICLE.border;
    case ContentItemType.UserExternalLink: {
      let summaryResult = contentItemSummary as UserExternalLinkContentItemSummaryResult;
      return getExternalLinkSummaryColor(summaryResult.externalLinkType);
    }
    case ContentItemType.DraftUserExternalLink: {
      let summaryResult = contentItemSummary as DraftUserExternalLinkContentItemSummaryResult;
      return getExternalLinkSummaryColor(summaryResult.externalLinkType);
    }
    default:
      return COLORS.DEFAULT;
  }
}

function getExternalLinkSummaryColor(externalLinkType: ExternalLinkType) {
  switch (externalLinkType) {
    case ExternalLinkType.Facebook:
      return COLORS.EXTERNAL_FACEBOOK.border;
    case ExternalLinkType.Instagram:
      return COLORS.EXTERNAL_INSTAGRAM.border;
    case ExternalLinkType.LinkedIn:
      return COLORS.EXTERNAL_LINKEDIN.border;
    case ExternalLinkType.Twitter:
      return COLORS.EXTERNAL_TWITTER.border;
    case ExternalLinkType.YouTube:
      return COLORS.EXTERNAL_YOUTUBE.border;
    case ExternalLinkType.Journal:
      return COLORS.EXTERNAL_JOURNAL.border;
  }
  return COLORS.EXTERNAL_DEFAULT.border;
}

export function getContentItemResultColor(contentItem: ContentItemResult): string {
  switch (contentItem.contentItemType) {
    case ContentItemType.Article:
      return COLORS.ARTICLE.border;
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return COLORS.DRUG.border;
    case ContentItemType.Decision:
      return COLORS.DECISION.border;
    case ContentItemType.Trial:
      return COLORS.TRIALS.border;
    case ContentItemType.Folio:
      return COLORS.FOLIO.border;
    case ContentItemType.UserArticle:
      return COLORS.USER_ARTICLE.border;
    case ContentItemType.UserExternalLink: {
      let summaryResult = contentItem as UserExternalLinkContentItemResult;
      return getExternalLinkResultColor(summaryResult.externalLinkType);
    }
    case ContentItemType.DraftUserExternalLink: {
      let summaryResult = contentItem as DraftUserExternalLinkContentItemResult;
      return getExternalLinkResultColor(summaryResult.externalLinkType);
    }
    default:
      return COLORS.DEFAULT;
  }
}

function getExternalLinkResultColor(externalLinkType: ExternalLinkType) {
  switch (externalLinkType) {
    case ExternalLinkType.Facebook:
      return COLORS.EXTERNAL_FACEBOOK.border;
    case ExternalLinkType.Instagram:
      return COLORS.EXTERNAL_INSTAGRAM.border;
    case ExternalLinkType.LinkedIn:
      return COLORS.EXTERNAL_LINKEDIN.border;
    case ExternalLinkType.Twitter:
      return COLORS.EXTERNAL_TWITTER.border;
    case ExternalLinkType.YouTube:
      return COLORS.EXTERNAL_YOUTUBE.border;
    case ExternalLinkType.Journal:
      return COLORS.EXTERNAL_JOURNAL.border;
  }
  return COLORS.EXTERNAL_DEFAULT.border;
}

export function getContentSummarySavedColor(contentSummary: ContentItemSummaryResult): string {
  switch (contentSummary.contentItemType) {
    case ContentItemType.Article:
      return COLORS.ARTICLE.filled;
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return COLORS.DRUG.filled;
    case ContentItemType.Decision:
      return COLORS.DECISION.filled;
    case ContentItemType.Trial:
      return COLORS.TRIALS.filled;
    case ContentItemType.Folio:
      return COLORS.FOLIO.filled;
    case ContentItemType.UserArticle:
      return COLORS.USER_ARTICLE.filled;
    case ContentItemType.DraftUserArticle:
      return COLORS.DRAFT_USER_ARTICLE.filled;
    case ContentItemType.UserExternalLink: {
      let summaryResult = contentSummary as UserExternalLinkContentItemSummaryResult;
      return getExternalLinkSummaryFilledColor(summaryResult.externalLinkType);
    }
    case ContentItemType.DraftUserExternalLink: {
      let summaryResult = contentSummary as DraftUserExternalLinkContentItemSummaryResult;
      return getExternalLinkSummaryFilledColor(summaryResult.externalLinkType);
    }
    default:
      return COLORS.DEFAULT;
  }
}

function getExternalLinkSummaryFilledColor(externalLinkType: ExternalLinkType) {
  switch (externalLinkType) {
    case ExternalLinkType.Facebook:
      return COLORS.EXTERNAL_FACEBOOK.filled;
    case ExternalLinkType.Instagram:
      return COLORS.EXTERNAL_INSTAGRAM.filled;
    case ExternalLinkType.LinkedIn:
      return COLORS.EXTERNAL_LINKEDIN.filled;
    case ExternalLinkType.Twitter:
      return COLORS.EXTERNAL_TWITTER.filled;
    case ExternalLinkType.YouTube:
      return COLORS.EXTERNAL_YOUTUBE.filled;
    case ExternalLinkType.Journal:
      return COLORS.EXTERNAL_JOURNAL.filled;
  }
  return COLORS.EXTERNAL_DEFAULT.filled;
}

export function getContentSavedColor(contentItemType: ContentItemType): string {
  switch (contentItemType) {
    case ContentItemType.Article:
      return COLORS.ARTICLE.filled;
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return COLORS.DRUG.filled;
    case ContentItemType.Decision:
      return COLORS.DECISION.filled;
    case ContentItemType.Trial:
      return COLORS.TRIALS.filled;
    case ContentItemType.Folio:
      return COLORS.FOLIO.filled;
    case ContentItemType.UserArticle:
      return COLORS.USER_ARTICLE.filled;
    case ContentItemType.DraftUserArticle:
      return COLORS.DRAFT_USER_ARTICLE.filled;
    case ContentItemType.UserExternalLink:
      return COLORS.USER_EXTERNAL_LINK.filled;
    case ContentItemType.DraftUserExternalLink:
      return COLORS.DRAFT_USER_ARTICLE.filled;
    default:
      return COLORS.DEFAULT;
  }
}
