import DOCEO_ICONS from "@/constants/icons";

export const SHARE_LEVEL_ICON = {
  PUBLIC: "",
  PRIVATE: DOCEO_ICONS.FOLIO_PRIVATE,
  RESTRICTED: DOCEO_ICONS.FOLIO_RESTRICTED,
};

export const SHARE_LEVEL_TEXT = {
  PUBLIC: "Public",
  PRIVATE: "Private",
  RESTRICTED: "Restricted",
};
