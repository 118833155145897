import {
  ContentItemResult,
  ContentItemSummaryResult,
  ContentItemType,
  DraftUserExternalLinkContentItemSummaryResult,
  ExternalLinkType,
  UserExternalLinkContentItemResult,
  UserExternalLinkContentItemSummaryResult,
} from "@/api/DoceoApi";
import DOCEO_ICONS from "@/constants/icons";

export function getContentIcon(contentItemSummary: ContentItemSummaryResult): string {
  switch (contentItemSummary.contentItemType) {
    case ContentItemType.Article:
      return DOCEO_ICONS.CONTENT_ARTICLE;
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return DOCEO_ICONS.CONTENT_DRUG;
    case ContentItemType.Decision:
      return DOCEO_ICONS.CONTENT_DECISION;
    case ContentItemType.Trial:
      return DOCEO_ICONS.CONTENT_TRIAL;
    case ContentItemType.UserArticle:
      return DOCEO_ICONS.CONTENT_ARTICLE;
    case ContentItemType.DraftUserArticle:
      return DOCEO_ICONS.CONTENT_DRAFT;
    case ContentItemType.UserExternalLink: {
      let summary = contentItemSummary as UserExternalLinkContentItemSummaryResult;
      return getExternalLinkIcon(summary.externalLinkType);
    }
    case ContentItemType.DraftUserExternalLink: {
      let summary = contentItemSummary as DraftUserExternalLinkContentItemSummaryResult;
      return getExternalLinkIcon(summary.externalLinkType);
    }
    default:
      return DOCEO_ICONS.CONTENT_ARTICLE;
  }
}

export function getSaveResultIcon(contentItemResult: ContentItemResult): string {
  switch (contentItemResult.contentItemType) {
    case ContentItemType.Article:
      return DOCEO_ICONS.SAVE_YELLOW;
    case ContentItemType.DrugBrandGroup:
    case ContentItemType.Drug:
      return DOCEO_ICONS.SAVE_BLUE;
    case ContentItemType.Decision:
      return DOCEO_ICONS.SAVE_GREEN;
    case ContentItemType.Trial:
      return DOCEO_ICONS.SAVE_ORANGE;
    case ContentItemType.UserArticle:
      return DOCEO_ICONS.SAVE_YELLOW;
    case ContentItemType.UserExternalLink: {
      let summary = contentItemResult as UserExternalLinkContentItemResult;
      return getExternalSaveIcon(summary.externalLinkType);
    }
    default:
      return DOCEO_ICONS.SAVE_DEFAULT;
  }
}

export function getSaveIcon(contentItemSummary: ContentItemSummaryResult): string {
  switch (contentItemSummary.contentItemType) {
    case ContentItemType.Article:
      return DOCEO_ICONS.SAVE_YELLOW;
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return DOCEO_ICONS.SAVE_BLUE;
    case ContentItemType.Decision:
      return DOCEO_ICONS.SAVE_GREEN;
    case ContentItemType.Trial:
      return DOCEO_ICONS.SAVE_ORANGE;
    case ContentItemType.UserArticle:
      return DOCEO_ICONS.SAVE_YELLOW;
    case ContentItemType.UserExternalLink: {
      let summary = contentItemSummary as UserExternalLinkContentItemSummaryResult;
      return getExternalSaveIcon(summary.externalLinkType);
    }
    default:
      return DOCEO_ICONS.SAVE_DEFAULT;
  }
}

export function getExternalSaveIcon(linkType: ExternalLinkType): string {
  switch (linkType) {
    case ExternalLinkType.Facebook:
      return DOCEO_ICONS.SAVE_FACEBOOK;
    case ExternalLinkType.Instagram:
      return DOCEO_ICONS.SAVE_INSTAGRAM;
    case ExternalLinkType.LinkedIn:
      return DOCEO_ICONS.SAVE_LINKEDIN;
    case ExternalLinkType.Twitter:
      return DOCEO_ICONS.SAVE_TWITTER;
    case ExternalLinkType.YouTube:
      return DOCEO_ICONS.SAVE_YOUTUBE;
    case ExternalLinkType.Journal:
      return DOCEO_ICONS.SAVE_PINK;
  }
  return DOCEO_ICONS.SAVE_DEFAULT;
}

export function getExternalLinkIcon(linkType: ExternalLinkType): string {
  switch (linkType) {
    case ExternalLinkType.Facebook:
      return DOCEO_ICONS.SAVE_FACEBOOK;
    case ExternalLinkType.Instagram:
      return DOCEO_ICONS.SOCIAL_INSTAGRAM;
    case ExternalLinkType.LinkedIn:
      return DOCEO_ICONS.SOCIAL_LINKEDIN;
    case ExternalLinkType.Twitter:
      return DOCEO_ICONS.SOCIAL_TWITTER;
    case ExternalLinkType.YouTube:
      return DOCEO_ICONS.SOCIAL_YOUTUBE;
    case ExternalLinkType.Journal:
      return DOCEO_ICONS.JOURNAL;
  }
  return DOCEO_ICONS.LINK;
}
