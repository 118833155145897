


















































































































import { AddFolioClippingModel, ContentItemSummaryResult, ContentItemType, FolioContentItemSummaryResult, FolioShareLevel, RemoveFolioClippingModel } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import { mapState, mapActions } from "pinia";
import { useFolioStore } from "@/store/folioStore";
import CreateFolioDialog from "@/components/contentItems/folios/CreateFolioDialog.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue"
import DOCEO_ICONS from "@/constants/icons";

import { SHARE_LEVEL_ICON } from "@/constants/folios";
import { getContentIcon } from "@/constants/contentIcons";
import { getContentSummaryColor } from "@/constants/contentColors";
import { getUserFriendlyContentItemType } from "@/constants/contentNames";

export default Vue.extend({
  name: "AddToFolioDialog",
  components: { CreateFolioDialog, DoceoImage, DoceoIcon },
  props: {
    contentItemSummary: Object as PropType<ContentItemSummaryResult>,
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  created() {
    this.fetchFolios();
  },

  data: () => ({
    drawer: null,
    items: [],
    isNewFolioOpen: false,
    folioSearchTerm: "",
    headers: [{ text: "Title", value: "title" }],
    DOCEO_ICONS,
  }),
  computed: {
    ...mapState(useFolioStore, ["myCreatedFolios"]),
    /**
     * Determine the color that should be used for each type of content
     */
    defaultColor(): string {
      return getContentSummaryColor(this.contentItemSummary);
    },
    /**
     * Display the icon appropriate for each type of content item
     */
    defaultIcon(): string {
      return getContentIcon(this.contentItemSummary);
    },
    /**
     * Get the user friendly type of content item
     */
    userFriendlyContentItemType(): string {
      return getUserFriendlyContentItemType(this.contentItemSummary.contentItemType);
    },
  },
  methods: {
    ...mapActions(useFolioStore, ["addFolioClipping", "removeFolioClipping", "fetchFolios"]),
    /**
     * Close both the create folio dialog and the current dialog
     */
    closeAllDialogs() {
      this.closeCreateFolioDialog();
      this.close();
    },
    /**
     * Close the dialog for creating new folios
     */
    closeCreateFolioDialog() {
      this.isNewFolioOpen = false;
    },
    /**
     * Open the dialog for creating new folios
     */
    createNewFolioClicked() {
      this.isNewFolioOpen = true;
    },
    /**
     * Emit an event to close the dialog
     */
    close() {
      this.$emit("close");
    },
    /**
     * Add the clipping to the selected folio and then emit an event to close the dialog
     */
    confirm(item: FolioContentItemSummaryResult) {
      if (item) {
        const clipping = new AddFolioClippingModel({
          folioId: item.contentItemId,
          contentItemId: this.contentItemSummary.contentItemId,
        });
        this.addFolioClipping(clipping);
        this.contentItemSummary.savedToFolioIds?.push(item.versionId);
        this.$emit("saved");
      }
    },
    /**
     * Remove the clipping from the selected folio and then emit an event to close the dialog
     */
    remove(item: FolioContentItemSummaryResult) {
      if (item) {
        const clipping = new RemoveFolioClippingModel({
          folioId: item.contentItemId,
          contentItemId: this.contentItemSummary.contentItemId,
        });
        this.removeFolioClipping(clipping);
        const itemIndex = this.contentItemSummary.savedToFolioIds?.indexOf(item.versionId) ?? -1
        if (itemIndex !== -1) {
          this.contentItemSummary.savedToFolioIds?.splice(itemIndex, 1);
        }
        this.$emit("saved");
      }
    },
    /**
     * Get the icons for each of the share levels
     */
    shareLevelIcon(shareLevel: FolioShareLevel): string {
      switch (shareLevel) {
        case FolioShareLevel.Public:
          return SHARE_LEVEL_ICON.PUBLIC;
        case FolioShareLevel.Private:
          return SHARE_LEVEL_ICON.PRIVATE;
        case FolioShareLevel.Restricted:
          return SHARE_LEVEL_ICON.RESTRICTED;
      }
    },
    /**
     * Determine if the content item has been saved to the folio
     */
    clippingInFolio(item: ContentItemSummaryResult): boolean {
      return this.contentItemSummary.savedToFolioIds?.includes(item.versionId) ?? false;
    },
  },
});
