import { ContentItemType } from "@/api/DoceoApi";

export function getUserFriendlyContentItemType(
  contentItemType: ContentItemType
): string {
  switch (contentItemType) {
    case ContentItemType.Article:
      return "Article";
    case ContentItemType.Drug:
    case ContentItemType.DrugBrandGroup:
      return "Drug";
    case ContentItemType.Decision:
      return "Decision";
    case ContentItemType.Trial:
      return "Trial";
    case ContentItemType.Folio:
      return "Folio";
    case ContentItemType.UserArticle:
      return "Article";
    case ContentItemType.DraftUserArticle:
      return "Draft Article";
    case ContentItemType.UserExternalLink:
      return "External Link";
    case ContentItemType.DraftUserExternalLink:
      return "Draft External Link";
    default:
      return "Unknown";
  }
}
