





















































































































































































































































import {
  Folio,
  FolioClient,
  FolioContentItemResult,
  FolioInvitationModel,
  FolioInvitationStatus,
  FolioShareLevel,
  FolioUserResult,
  GetFolioAttachment,
  IFolio,
  IFolioInvitationModel,
  IInviteUser,
  InviteUser,
  IUpdateFolio,
  UpdateFolio,
  UserClient,
} from "@/api/DoceoApi";
import Vue, { PropType } from "vue";

import { mapActions } from "pinia";
import { mapGetters } from "vuex";
import { useFolioStore } from "@/store/folioStore";

import { SHARE_LEVEL_TEXT, SHARE_LEVEL_ICON } from "@/constants/folios";
import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

interface ListItem {
  text: string;
  value: string;
  logo?: string;
}

export default Vue.extend({
  name: "CreateFolioDialog",
  components: { DoceoImage, DoceoIcon },
  mixins: [validationMixin],
  validations: {
    summary: { maxLength: maxLength(500) },
    title: { maxLength: maxLength(100) },
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    folio: {
      type: Object as PropType<FolioContentItemResult>,
      required: false,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * Create the list of images the user can select for folios.
   */
  async created() {
    if (this.folio) {
      this.title = this.folio.title ?? "";
      this.summary = this.folio.summary ?? "";
      this.accessLevel = this.folio.shareLevel;

      let folioClient = new FolioClient();
      this.invitedUsers = await folioClient.getFolioUsers(this.folio.versionId);
    }

    this.getFolioLogos();
  },
  data: () => ({
    isFormValid: false, // Keep track of whether the form is valid
    title: null as string | null, // The title the user has entered for the folio
    summary: null as string | null, // the summary/description the user has entered for the folio
    logo: null as GetFolioAttachment | null, // The logo the user has selected for the folio
    accessLevel: null as string | null, // The access/share level the user has
    titleRules: [(v: string) => !!v || "Folio name is required"], // Ensure that the title field is required
    logoRules: [(v: string) => !!v || "Folio image is required"], // Ensure that the logo field is required
    accessLevelRules: [(v: string) => !!v || "Access Level is required"], // Ensure that the access level field is required
    newUserFirstNameRules: [(v: string) => !!v || "First name is required"],
    newUserLastNameRules: [(v: string) => !!v || "Last name is required"],
    newUserEmailRules: [(v: string) => !!v || "Email address is required"],
    isSubmitting: false, // Whether the form is currently being submitted,
    images: [] as GetFolioAttachment[],
    accessLevelError: null as string | null,
    isMenuOpen: false,
    invitedUsers: [] as FolioUserResult[],
    inviteEmail: "",
    DOCEO_ICONS,
    isInviteUserToPlatformShowing: false,
    isNewUserFormValid: false,
    newUserFirstName: "",
    newUserLastName: "",
    newUserEmail: "",
  }),
  methods: {
    ...mapActions(useFolioStore, ["createFolio", "updateFolio", "getDefaultLogos", "deleteFolio"]),
    // Remove a user from the folio
    async removeInvite(userId: string) {
      let folioClient = new FolioClient();

      try {
        await folioClient.removeUser(this.folio.contentItemId, userId);
        this.invitedUsers = this.invitedUsers.filter((e) => e.userId != userId);
      } catch (x) {}
    },
    async inviteToPlatform() {
      if (this.isNewUserFormValid) {
        let userClient = new UserClient();
        let userInvite: IInviteUser = {
          firstName: this.newUserFirstName,
          lastName: this.newUserLastName,
          emailAddress: this.newUserEmail,
          chatroomId: "",
          folioId: this.folio.contentItemId,
        };

        await userClient.inviteUser(new InviteUser(userInvite));

        let newUser = new FolioUserResult({
          userId: "",
          name: "",
          email: this.newUserEmail,
          status: FolioInvitationStatus.Pending,
        });
        this.invitedUsers.push(newUser);
        this.inviteEmail = "";
        this.isInviteUserToPlatformShowing = false;
      }
    },
    // Invite a user to the folio
    async inviteUser() {
      if (this.inviteEmail == "") {
        return;
      }

      let folioClient = new FolioClient();
      let userClient = new UserClient();
      let folioInvitation: IFolioInvitationModel = {
        email: this.inviteEmail,
      };

      try {
        let doesEmailExist = await userClient.doesEmailExist(this.inviteEmail);

        if (doesEmailExist) {
          let newUser = await folioClient.inviteUser(this.folio.contentItemId, new FolioInvitationModel(folioInvitation));
          newUser.email = this.inviteEmail;
          this.inviteEmail = "";
          this.invitedUsers.push(newUser);
        } else {
          this.newUserEmail = this.inviteEmail;
          this.newUserFirstName = "";
          this.newUserLastName = "";
          this.isInviteUserToPlatformShowing = true;
        }
      } catch (x) {
        this.inviteEmail = "";
      }
    },
    /**
     *
     */
    selectImage(image: GetFolioAttachment) {
      this.logo = image;
      this.isMenuOpen = false;
    },
    /**
     * When an access level is selected, clear out any errors and set the selected level
     */
    accessLevelSelected(accessLevel: ListItem) {
      this.accessLevel = accessLevel.value;
      this.accessLevelError = null;
    },
    /**
     * If the user clicks delete
     */
    async deleteFolioClicked() {
      this.deleteFolio(this.folio.contentItemId);
      this.close();
    },
    /**
     * Retrieve a list of folio images
     */
    async getFolioLogos() {
      this.images = await this.getDefaultLogos();

      // If we are editing a folio, set the logo to the be the one passed in
      if (this.folio) {
        let folioImages = this.images.filter((e) => e.id == this.folio.logo?.id);
        if (folioImages.length > 0) {
          this.logo = folioImages[0];
        }
      }
    },
    /**
     * Emit an event indicating the back button was pressed
     */
    back() {
      this.$emit("back");
    },
    /**
     * Emit an event to close the dialog
     */
    close() {
      this.$emit("close");
    },
    /**
     * When the user clicks the button, determine if it's
     * an update or create based on whether a folio was passed in
     */
    updateOrCreateClicked() {
      this.createFolioForm.validate();

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (this.isFormValid && this.accessLevel) {
        this.folio ? this.update() : this.create();
      } else if (!this.accessLevel) {
        this.accessLevelError = "Access level is required";
      }
    },
    /**
     * Validate the form and create the new folio.
     */
    async update() {
      this.createFolioForm.validate();
      this.accessLevelError = null;

      if (this.isFormValid && this.accessLevel) {
        const folioClient = new FolioClient();
        this.isSubmitting = true;
        let updatedFolio = new UpdateFolio();
        let updatedModel: IUpdateFolio = {
          logoAttachmentId: this.logo?.id ?? "",
          title: this.title ?? "",
          summary: this.summary ?? "",
          shareLevel: FolioShareLevel[this.accessLevel] ?? FolioShareLevel.Private,
          folioId: this.folio.contentItemId,
        };
        updatedFolio.init(updatedModel);

        try {
          await this.updateFolio(updatedFolio);
          this.$emit("confirm");
        } catch (e) {
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    /**
     * Validate the form and create the new folio.
     */
    async create() {
      this.createFolioForm.validate();

      if (this.isFormValid && this.accessLevel) {
        this.isSubmitting = true;
        let newFolio = new Folio();
        let newModel: IFolio = {
          logoAttachmentId: this.logo?.id ?? "",
          title: this.title ?? "",
          summary: this.summary ?? "",
          shareLevel: FolioShareLevel[this.accessLevel] ?? FolioShareLevel.Private,
          authorId: this.userId,
          contentIds: undefined,
        };
        newFolio.init(newModel);

        try {
          await this.createFolio(newFolio);
          this.$emit("confirm");
        } catch (e) {
        } finally {
          this.isSubmitting = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["userId"]),
    /**
     * Return whether the currently selectged access level is restriced
     */
    isRestrictedLevel(): boolean {
      return this.accessLevel == FolioShareLevel.Restricted;
    },
    /**
     * This takes the loginForm ref and casts it so that there is a validate method description for typescript
     */
    createFolioForm(): Vue & { validate: () => boolean } {
      return this.$refs.createFolioForm as Vue & { validate: () => boolean };
    },
    /**
     * Return a list of the restriction levels the user can choose from
     */
    restrictionLevels(): ListItem[] {
      return [
        {
          text: SHARE_LEVEL_TEXT.PRIVATE,
          value: FolioShareLevel.Private,
        },
        {
          text: SHARE_LEVEL_TEXT.RESTRICTED,
          value: FolioShareLevel.Restricted,
        },
        {
          text: SHARE_LEVEL_TEXT.PUBLIC,
          value: FolioShareLevel.Public,
        },
      ];
    },
    /**
     * Summary error checking
     */
    summaryErrors() {
      const errors = [] as string[];
      if (!this.$v.summary.$dirty) return errors;
      !this.$v.summary.maxLength && errors.push("Summary must be 500 characters or less");
      return errors;
    },
    /**
     * Title error checking
     */
    titleErrors() {
      const errors = [] as string[];
      if (!this.$v.summary.$dirty) return errors;
      !this.$v.summary.maxLength && errors.push("Title must be 100 characters or less");
      return errors;
    },
  },
});
